import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-style-popover',
  templateUrl: './style-popover.component.html',
  styleUrls: ['./style-popover.component.scss'],
})
export class StylePopoverComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
