import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { IonSlides, ModalController } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { BookDesignerModalComponent } from 'src/app/components/modals/book-designer-modal/book-designer-modal.component';
import { LoginModalComponent } from 'src/app/components/modals/login/login-modal.component';
import { Project } from 'src/app/models/project.model';
import { AccountService } from 'src/app/services/account.service';
import { DesignerService } from 'src/app/services/designer.service';
import { ProjectService } from 'src/app/services/project.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserConfirmationModalComponent } from 'src/app/components/modals/user-confirmation-modal/user-confimation-modal.component';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-designer-portfolio',
  templateUrl: './designer-portfolio.page.html',
  styleUrls: ['./designer-portfolio.page.scss'],
})
export class DesignerPortfolioPage implements OnInit, OnDestroy {
  @ViewChild('slides') slides: IonSlides;

  @ViewChild(IonContent) content: IonContent;

  isScrolledDown: boolean;

  designerId: any;
  designerDetails$: BehaviorSubject<any>;

  designerDetailsSubscription$: Subscription;

  isAdmin = false;

  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    public spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private designerService: DesignerService,
    private accountService: AccountService,
    private modalController: ModalController,
    private translate: TranslateService
  ) {
    this.designerDetails$ = new BehaviorSubject(null);

    this.accountService.currentUser$.subscribe(user => {
      this.isAdmin = user.roles[0] === 'Admin';
    });
  }

  ngOnInit(): void {
    this.designerId = this.route.snapshot.params.id;
    this.designerDetailsSubscription$ = this.designerService
      .getDesignerPortfolio(this.designerId)
      .subscribe((res) => {
        this.designerDetails$.next(res);
      });
  }

  ngOnDestroy(): void {
    this.designerDetailsSubscription$.unsubscribe();
  }

  async logScrollEnd() {
    const scrollElement = await this.content.getScrollElement(); // get scroll element
    this.isScrolledDown = scrollElement.scrollTop > 70;
  }

  getDesignerServices(): Array<string> {
    let services: Array<string>;
    this.designerDetails$.subscribe((res) => {
      const option1 = res.designerInfo.areaOfExpertise.split('\n').filter((s) => s !== '').map((s) => s.trim()); // split string by new line and remove empty strings and map to remove white spaces
      const option2 = res.designerInfo.areaOfExpertise.split(',').filter((s) => s !== '').map((s) => s.trim()); // split string by comma and remove empty strings and map to remove white spaces
      const temp = option1.length > option2.length ? option1 : option2; // return the array with the most elements
      services = temp.map(s => s.split(' - ')[0]); // split string by ' - ' and return the first element (service name)
    });
    return services;
  }

  getOptions() {
    return window.innerWidth > 700
      ? { slidesPerView: 2 }
      : { slidesPerView: 1 };
  }

  removeProjectDraft() {
    this.projectService.setCurrentProject(new Project());
  }

  checkIfUserLoggedIn(): boolean {
    let isUserLoggedIn = false;
    this.accountService.currentUser$.subscribe((user) => {
      if (user) {
        isUserLoggedIn = true;
      }
    });
    return isUserLoggedIn;
  }

  async openLoginModal(designer): Promise<void> {
    const modal = await this.modalController.create({
      component: LoginModalComponent,
      cssClass: 'medium-modal-css',
      backdropDismiss: true,
      swipeToClose: false,
      componentProps: { bookDesigner: true, designer },
    });

    await modal.present();
  }

  async paymentModal(designer) {
    const modal = await this.modalController.create({
      component: BookDesignerModalComponent,
      componentProps: { designer, moodboard: true },
      cssClass: 'book-designer-modal-css',
    });

    await modal.present();
  }

  async bookConsultation() {
    this.designerDetails$.subscribe(async (res) => {
      if (!this.checkIfUserLoggedIn()) {
        await this.openLoginModal(res);
        return;
      }
      await this.paymentModal(res);
    });
  }

  getFeaturedPortfolios(): Array<any> {
    let featuredPortfolios: Array<any>;
    this.designerDetails$.subscribe((designer) => {
      featuredPortfolios = designer.designerPortfolio.filter(
        (d) => d.featured === 1
      );
    });
    return featuredPortfolios;
  }

  onFileChange(event) {
    if (event.target.files[0]) {
      this.spinner.show();
      const formData = new FormData();
      formData.append('photoPath', event.target.files[0]);
      this.updateProfileImage(formData);
    } else {
      this.spinner.hide();
    }
  }

  updateProfileImage(formData: FormData) {
    this.http
      .post(`${this.apiUrl}designers/changeDesignerProfileImage/${this.designerId}`, formData)
      .pipe(
        catchError((err) => {
          this.spinner.hide();
          this.notifier.show({
            message: err.error.message,
            type: 'error',
          });
          console.log(err);
          return throwError(err);
        })
      )
      .subscribe((res: { photoPath: string } | string) => {
        this.spinner.hide();
        if (typeof res === 'object' && res.photoPath) {
          this.designerDetailsSubscription$ = this.designerService
            .getDesignerPortfolio(this.designerId)
            .subscribe((designer) => {
              this.designerDetails$.next(designer);
            });
          this.notifier.show({
            message: this.translate.instant('NOTIFY.profile-image-updated'),
            type: 'success',
          });
        } else {
          this.notifier.show({
            message: res.toString(),
            type: 'error',
          });
        }
      });
  }

  async uploadPortfolioImages(event) {
    console.log(event);

    if (event.target.files.length > 0) {
      this.spinner.show();
      const formData = new FormData();
      for (const file of event.target.files) {
        formData.append('portfolioImages', file);
      }

      this.http
        .post(`${this.apiUrl}designers/designerPortfolioImage/${this.designerId}`, formData)
        .pipe(
          catchError((err) => {
            this.spinner.hide();
            this.notifier.show({
              message: err.error.message,
              type: 'error',
            });
            for (const error of err.error.errors) {
              this.notifier.show({
                message: error,
                type: 'error',
              });
            }
            console.log(err);
            return throwError(err);
          })
        )
        .subscribe((res: string[] | null) => {
          this.spinner.hide();
          console.log(res);
          if (res !== null && res instanceof Array) {
            this.notifier.show({
              message: 'Some portfolio images were not uploaded',
              type: 'success',
            });
            for (const error of res) {
              this.notifier.show({
                message: error,
                type: 'error',
              });
            }
          } else {
            this.notifier.show({
              message: 'All portfolio images were uploaded',
              type: 'success',
            });
          }
          this.designerDetailsSubscription$ = this.designerService
            .getDesignerPortfolio(this.designerId)
            .subscribe((designer) => {
              this.designerDetails$.next(designer);
            });
        });

    } else {
      this.spinner.hide();
    }
  }

  async toggleFeatured(portfolioImageId: number, portfolioImage: { featured: number }) {
    this.spinner.show();
    this.http.patch(`${this.apiUrl}designers/designerPortfolioImage/${this.designerId}/${portfolioImageId}`, {
      featured: portfolioImage.featured === 1 ? 0 : 1
    })
      .pipe(
        catchError((err) => {
          this.spinner.hide();
          console.log(err);
          this.notifier.show({
            message: err.error.message,
            type: 'error',
          });
          return throwError(err);
        })
      )
      .subscribe((res: object | string | null) => {
        if (typeof res === 'object') {
          this.designerDetailsSubscription$ = this.designerService
            .getDesignerPortfolio(this.designerId)
            .subscribe((designer) => {
              this.designerDetails$.next(designer);
            });
          this.notifier.show({
            message: 'Portfolio image updated',
            type: 'success',
          });
          this.spinner.hide();
        } else {
          this.notifier.show({
            message: res.toString(),
            type: 'error',
          });
        }
      });
  }

  async deletePortfolioImage(portfolioImageId: number) {
    const modal = await this.modalController.create({
      component: UserConfirmationModalComponent,
      componentProps: {
        title: 'Delete Portfolio Image',
        message: 'Are you sure you want to delete this portfolio image?',
        icon: 'trash',
        options: [
          {
            text: 'Delete',
            isPrimary: true,
            callback: () => {
              this.modalController.dismiss();
              this.spinner.show();
              this.http.delete(`${this.apiUrl}designers/designerPortfolioImage/${this.designerId}/${portfolioImageId}`)
                .pipe(
                  catchError((err) => {
                    this.spinner.hide();
                    console.log(err);
                    this.notifier.show({
                      message: err.error.message,
                      type: 'error',
                    });
                    return throwError(err);
                  })
                )
                .subscribe((res: object | string | null) => {
                  this.spinner.hide();
                  this.designerDetailsSubscription$ = this.designerService
                    .getDesignerPortfolio(this.designerId)
                    .subscribe((designer) => {
                      this.designerDetails$.next(designer);
                    });
                  if (typeof res === 'object') {
                    this.notifier.show({
                      message: this.translate.instant('NOTIFY.portfolio-image-deleted'),
                      type: 'success',
                    });
                  } else {
                    this.notifier.show({
                      message: res.toString(),
                      type: 'error',
                    });
                  }
                });
            }
          },
          {
            text: 'Cancel',
            isPrimary: false,
            callback: () => {
              this.modalController.dismiss();
            }
          }
        ]

      },
      cssClass: 'add-designer-modal-css',
    });
    await modal.present();
  }

  getCurrentLang(): string {
    return this.translate.currentLang;
  }
}
