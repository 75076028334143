/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-user-confirmation-modal',
  templateUrl: './user-confirmation-modal.component.html',
  styleUrls: ['./user-confirmation-modal.component.scss'],
})
export class UserConfirmationModalComponent implements OnInit {


  icon?: string;

  title: string;

  message: string;

  options: {
    text: string;
    isPrimary: boolean;
    callback: () => void;
  }[];


  constructor(
    private modalController: ModalController,
  ) {
  }

  ngOnInit() {

  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
