import { Component } from '@angular/core';

@Component({
  selector: 'app-how-it-works-page',
  templateUrl: './how-it-works.page.html',
  styleUrls: ['./how-it-works.page.scss'],
})
export class HowItWorksPage {
  constructor() {}
}
