import { Component } from '@angular/core';

@Component({
  selector: 'app-book-designer-profile-page',
  templateUrl: './book-designer-profile.page.html',
  styleUrls: ['./book-designer-profile.page.scss'],
})
export class BookDesignerProfilePage {
  constructor() {}
}
