// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://intiri-test.azurewebsites.net/api/',
  // apiUrl: 'https://localhost:7004/api/',
  // apiUrl: 'https://app.intiri.no/api/',
  //apiUrl: 'http://40.114.78.222/api/',
  apiUrl: 'https://qa.app.intiri.no/api/',
  stripe_key:
    'pk_test_51NY6Q5FTILFGL9YzGpeXexnUTjCU4yK3CZi1SeUrRxXSpmDrVs6tWnf4xGSiZk9Qw0hEWHczxBAEiAon1FS0bkaX00Usbmrd05',

  cloudinaryCloud: 'dirqhh7af',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
