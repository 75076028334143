/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NumberValueAccessor, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DesignerService } from 'src/app/services/designer.service';
import { UserService } from 'src/app/services/user.service';


type DesignerInfo = {
  id?: number;

  address?: string;
  openToHomeVisit?: boolean;
  travelDistance?: string;
  areaOfExpertise?: string;
  // areaOfExpertise_NO?: string;
  areaOfExpertiseForProfile?: string;
  // areaOfExpertiseForProfile_NO?: string;
  style?: string;

  price?: number;
  position?: string;
  position_EN?: string;

  profileDescription?: string;
  profileDescription_NO?: string;

  displayOrder?: number;
};


@Component({
  selector: 'app-add-designer-info-modal',
  templateUrl: './add-designer-info-modal.component.html',
  styleUrls: ['./add-designer-info-modal.component.scss'],
})
export class AddDesignerInfoModalComponent implements OnInit {
  public addDesignerInfoForm: FormGroup;
  public editDesignerInfoForm: FormGroup;
  public isFormSubmited = false;

  formatSnakeCaseToRegularText(str) {
    return str
      // Convert camelCase to snake_case
      .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
      // Convert snake_case to regular text
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  add;
  added;
  delete;
  edit;




  item: {
    id: number;
    designerInfo: DesignerInfo;
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  EN: boolean = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO: boolean = false;

  designerInfo: DesignerInfo = {
    id: null,
    address: '',
    openToHomeVisit: false,
    travelDistance: '',
    areaOfExpertise: '',
    // areaOfExpertise_NO: '',
    areaOfExpertiseForProfile: '',
    // areaOfExpertiseForProfile_NO: '',
    style: '',
    price: 0,
    position: '',
    position_EN: '',
    profileDescription: '',
    profileDescription_NO: '',

    displayOrder: null,
  };

  designerInfoKeys = Object.keys(this.designerInfo).filter((key) => key !== 'id');

  designerInfoTypes = {
    address: 'string',
    openToHomeVisit: 'checkbox',
    travelDistance: 'string',
    areaOfExpertise: 'string',
    areaOfExpertise_NO: 'string',
    areaOfExpertiseForProfile: 'string',
    areaOfExpertiseForProfile_NO: 'string',
    style: 'string',
    price: 'number',
    position: 'string',
    position_EN: 'string',
    profileDescription: 'string',
    profileDescription_NO: 'string',
    displayOrder: 'number',
  };

  constructor(
    private modalController: ModalController,
    private designerService: DesignerService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {
    this.addDesignerInfoForm = this.formBuilder.group({
      address: [''],
      openToHomeVisit: [''],
      travelDistance: [''],
      areaOfExpertise: [''],
      // areaOfExpertise_NO: [''],
      areaOfExpertiseForProfile: [''],
      // areaOfExpertiseForProfile_NO: [''],
      style: [''],
      price: [''],
      position: [''],
      position_EN: [''],
      profileDescription: [''],
      profileDescription_NO: [''],
    });
    this.editDesignerInfoForm = this.formBuilder.group({
      address: [''],
      openToHomeVisit: [''],
      travelDistance: [''],
      areaOfExpertise: [''],
      // areaOfExpertise_NO: [''],
      areaOfExpertiseForProfile: [''],
      // areaOfExpertiseForProfile_NO: [''],
      style: [''],
      price: [''],
      position: [''],
      position_EN: [''],
      profileDescription: [''],
      profileDescription_NO: [''],

      displayOrder: [''],
    });
  }

  ngOnInit() {
    if (this.edit) {
      this.designerInfo = this.item.designerInfo;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  addDesignerInfo() {
    this.spinner.show();
    this.isFormSubmited = true;
    if (!this.addDesignerInfoForm.valid) {
      this.spinner.hide();
      return;
    }
    this.designerInfo.id = undefined;
    this.designerInfo.displayOrder = undefined;
    this.designerService.addDesignerInfo(this.item.id, this.designerInfo).subscribe(
      (res) => {
        this.spinner.hide();
        if (typeof res === 'object') {
          this.designerService.getDesigners();
          this.openSuccessModal();
        }
      },
      (e) => {
        this.spinner.hide();
        this.notifier.show({
          message: e.error,
          type: 'error',
        });
      }
    );
  }


  editDesignerInfo() {
    this.spinner.show();
    this.isFormSubmited = true;
    if (!this.editDesignerInfoForm.valid) {
      this.spinner.hide();
      return;
    }
    this.designerService.editDesignerInfo(this.item.id, this.designerInfo.id, this.designerInfo).subscribe(
      (res) => {
        this.spinner.hide();
        if (typeof res === 'object') {
          this.designerService.getDesigners();
          this.openSuccessModal();
        }
      },
      (e) => {
        this.spinner.hide();
        this.notifier.show({
          message: e.error,
          type: 'error',
        });
      }
    );
  }

  async openSuccessModal() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: AddDesignerInfoModalComponent,
      componentProps: { added: true },
      cssClass: 'added-designer-modal-css',
    });

    await modal.present();
  }
}
